import React from 'react';
import { graphql } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import BlogContent from "../components/blog-content";
import Breadcrumbs from "../components/breadcrumbs"
import Thumbnail from "../components/thumbnail";
import Posts from '../components/posts';
import Layout from '../components/layout';

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead 
    {...props}
    title={`Aktualne promocje w ${props.data?.mysqlBank?.name} | akredo.pl`}
    description={props.data?.mysqlTerms?.description || `Sprawdź najnowsze promocje w ${props.data?.mysqlBank?.name}! Zdobądź ciekawe nagrody i skorzystaj z ofert specjalnych! Śpiesz się - oferty są ograniczone czasowo!`}
  >
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
)

const BankPromoList = ({
  data: { mysqlTerms, mysqlBank, allMysqlBlog }, 
  pageContext: { breadcrumbs },
  location: { pathname },
}) => {
  return (
    <Layout pathname={pathname}>
      <div className={[b.container, b.pt3,'breadcrumbs-mobile',b.ptLg5,"scroll-inner"].join(" ")}>
        <Breadcrumbs theme='white' items={breadcrumbs} />
      </div>
      <div className={[b.container, b.pbLg5, b.pt3].join(" ")}>
        <div className={[b.row,b.my5,b.justifyContentBetween].join(' ')}>
          <div className={[b.colLg8, b.mb3, b.mbLg0].join(' ')}>
            <h1 className={["blackToWhite",'big-title',b.mt4].join(' ')}>Promocje {mysqlBank.name}</h1>

            <div className={[b.mt4, 'font-size-20',b.fontWeightNormal, 'blackToWhite'].join(' ')}>
              {allMysqlBlog.nodes.length > 0 ? (<>
                <p>Sprawdź najnowsze, aktualne <b>Promocje {mysqlBank.name}</b>! Skorzystaj z ofert specjalnych i zdobądź ciekawe nagrody. Śpiesz się - oferty są ograniczone czasowo! Wybierz propozycję, która Cię interesuje i zapoznaj się z jej warunkami.</p>
                <p>Poniżej znajdziesz proste instrukcje, bez zbędnych informacji - jak skorzystać z aktualnych promocji organizowanych przez ten bank! Dowiedz się: na czym polega oferta, do kogo jest kierowana oraz co dokładnie trzeba robić - w jakich terminach i w jakiej kolejności - aby otrzymać całą nagrodę.</p>
              </>) : (<>
                <p>Niestety, ten bank aktualnie nie oferuje żadnych promocji! Zespół analityków Akredo na bieżąco sprawdza aktualne oferty specjalne proponowane przez każdy bank w Polsce.</p>
                <p>Gdy tylko pojawią się nowe <b>Promocje {mysqlBank.name}</b> wszystkie informacje dotyczące oferty specjalnej: terminy, wymagania banku i nasze wskazówki jak uzyskać nagrodę znajdziesz właśnie tutaj! Gwarantujemy! Sprawdzaj naszą stronę regularnie!</p>
              </>)}
            </div>
          </div>
          <div className={['bank-list-products-box',b.colLg3,b.p4,b.dFlex,b.alignItemsCenter,b.justifyContentCenter].join(' ')}>
            <Thumbnail image={mysqlBank.mysqlImage} alt={mysqlBank.name} />
          </div>
        </div>

        {allMysqlBlog.nodes.length > 0 && (<>
          <h2 className={["title", b.mb4].join(' ')}>Lista wszystkich promocji {mysqlBank.name}</h2>
          <Posts items={allMysqlBlog.nodes} bankpromo />
        </>)}
  
        <div className={["blog-content", b.row, b.mtLg3,'blueToGray', 'dark-li'].join(" ")}>
          <div className={[ b.colXl8, 'dark-theme-listing'].join(' ')}>
          {mysqlTerms.description && (
            <BlogContent content={mysqlTerms.description} className={[b.pr1, b.mb4].join(" ")}/>
          )}
          {mysqlTerms.description_alt && (
            <BlogContent content={mysqlTerms.description_alt} className={[b.pr1, b.mb4].join(" ")}/>
          )}
          {mysqlTerms.description_additional && (
            <BlogContent content={mysqlTerms.description_additional} className={[b.pr1, b.mb4].join(" ")}/>
          )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BankPromoList;

export const query = graphql`
  query BankPromoList($mysqlId: Int!, $bankId: Int!) {
    mysqlTerms(mysqlId: { eq: $mysqlId }) {
      title
      description
    }
    mysqlBank(mysqlId: { eq: $bankId }) {
      name
      mysqlId
      mysqlImage {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    allMysqlBlog(filter: {promo_bonus: {ne: ""}, is_promo_end: { ne: 1 }, blogTerms: {elemMatch: {term_id: {eq: $mysqlId}}}}, sort: {fields: mysqlId, order: DESC}) {
      nodes {
        title
        image_description
        description
        url
        term_normalized
        terms_path
        mysqlImages {
          childImageSharp {
            gatsbyImageData(width: 70)
          }
        }
      }
    }
    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "articles" }}) {
      nodes {
        ...SeoData
      }
    }
  }
`
